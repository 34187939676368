




import { Component, Vue } from 'vue-property-decorator'
import { Logs } from '@/api'

@Component({
  components: {
    LogsTable: () => import('./LogsTable.vue')
  }
})
export default class UserPunishmentsLogs extends Vue {
  data = []

  async mounted() {
    const userId = this.$route.params.id
    const punishmentLogs = userId
      ? await Logs.getUserPunishmentsLogsById(userId)
      : []

    this.data = punishmentLogs.map(e => {
      return {
        ...e,
        dateTime: new Date(e.dateTime).toUTCString()
      }
    })
  }
}
